import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/IT/layout'
import BannerLanding from '../../components/IT/BannerResults'

import pic08 from '../../assets/images/pic08.jpg'
import pic09 from '../../assets/images/pic09.jpg'
import pic10 from '../../assets/images/pic10.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
        <section id="one">
                <div className="inner">

              

                    <header className="major">
                        <h2>Educational resources </h2>
                        <h4> Training Seminar - Bucharest  </h4>
                        <a href="https://drive.google.com/open?id=1wG_CNF2IxEq121_3HrKiYg0zSSMzQ-dH"> UNIDANS Training Seminar </a><br/>

                        <h4 style = {{marginTop: "30px"}}> Training Seminar - Tarega  </h4>
                        <a href="https://drive.google.com/open?id=1xmt9puiAUTrY1tNlV60aFxbUpTv1n2Oj"> PPT Maria Montilla  </a>
                        <a href="https://drive.google.com/open?id=1ZEplfX4WXisgApX6hJ60uD-EqYfLgg-1"> PPT Jannick Niort  </a>

                        <br/>
                        <h4 style = {{marginTop: "30px"}}> Training Seminar - Turin  </h4>
                        <a href="https://drive.google.com/open?id=1JtV5dKC_0b3zr40tZSH8MWds1Y4_TkUY"> PPT Carluccio </a>
                        <br/>
                        <a href="https://drive.google.com/open?id=1WCzE-d6KQnu7_nNfbwVSwNfPn9rR3Y4E"> PPT Baffert  </a> <br/>
                        <a href="https://drive.google.com/open?id=1d2S_aQOQow9NUIc35Iq5qqIMAjWV34FU"> PPT Costamagna  </a> <br/>
                    </header>

                    {/* <header className="major">
                        <h2>Network</h2>
                    </header>
                    <p> UNIDANS Network in Romania: <br/><br/>
Universitatea Națională de Educație Fizică și Sport din București<br/><br/>
Asociatia Down Plus Bucuresti<br/><br/>
Asociatia Sindrom Down Bucuresti<br/><br/>
Asociatia Be a Butterfly<br/><br/>
Asociatia Hoffnung<br/><br/>
Fundatia Mana de Lucru<br/><br/>
Asociatia de Cultura si Tango </p> */}

                    <header className="major">
                        <h2>Training course resources </h2>
                        <a href="https://www.youtube.com/channel/UCAFO02IrgwCIDPiMD1MKsOg?view_as=subscriber"> UNIDANS youtube channel </a>
                    </header>

                    <header className="major">
                        <h2>Integrated Dance - Guide for the teachers, coaches and instructors </h2>
                        <a href="https://drive.google.com/open?id=1WXWqoWPT2zO8cXoQe-uOLNH92Q_O3av8"> Download Dance Guide  </a><br/>

                    </header>

                    {/* <header className="major">
                        <h2>Course Guide</h2>
                    </header> */}
                </div>
            </section>
            {/* <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Orci maecenas</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> */}
                {/* <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Rhoncus magna</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Sed nunc ligula</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section> */}
        </div>

    </Layout>
)

export default Landing